import { useRef, useState, useCallback, useEffect, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsFolderTab,
  TdsFolderTabs,
  TdsIcon,
  TdsLink,
  TdsMessage,
  TdsModal,
  TdsSpinner,
  TdsTextField,
  TdsTooltip,
} from '@scania/tegel-react';

import { useCreateDashboardMutation, useDeleteDashboardMutation, useUpdateDashboardMutation } from '../../state/views/query';
import { groupParams } from '../../utils/report/paramsGroups';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';
import { allGroupsFullySelected, getGroupsCountPerCategory, isAccordionItemExpanded, updateParamsList } from '../../utils/views';
import ParameterGroupsContainer from '../containers/ParameterGroupsContainer';
import styles from '../../styles/ViewModal.module.css';
import ConfirmationModal from '../common/ConfirmationModal';
import { getTableParamsExpanded } from '../../utils/general';
import { uuidv4 } from '../../utils/api';
import TableHeaderReorderContainer from '../containers/TableHeaderReorderContainer';

type ViewModalProps = {
  header: string;
  selector: string;
  size: ModalSize;
  propulsionConsumptionUnit: string;
  gasConsumptionUnit: string;
  tableWidgetDetails: DashboardDetails;
  pushNotification: Function;
  updateSelectedView: Function;
  setSelector: Function;
};

const ViewModal = ({
  header,
  selector,
  size,
  propulsionConsumptionUnit,
  gasConsumptionUnit,
  tableWidgetDetails,
  pushNotification,
  updateSelectedView,
  setSelector
}: ViewModalProps) => {
  const { t } = useTranslation();
  const dashboardReference = tableWidgetDetails?.dashboardReference || '';

  const [createDashboard, { isLoading: isSavingDashboard }] = useCreateDashboardMutation();
  const [updateDashboard, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();
  const [deleteDashboard] = useDeleteDashboardMutation();

  const [confirmationSelector, setConfirmationSelector] = useState<string>('');
  const [viewNameValue, setViewNameValue] = useState<string>('');
  const [searchedText, setSearchedText] = useState<string>('');
  const [viewNameError, setViewNameError] = useState(false);
  const [viewMetrics, setViewMetrics] = useState<Array<{
    columnName: string;
    subColumns: Array<string>
  }>>([]);
  const [visibleCategories, setVisibleCategories] = useState(Array(groupParams.length).fill(0, 0));
  const [paramsSelected, setParamsSelected] = useState(false);
  const [expandedParameterGroups, setExpandedParameterGroups] = useState<Array<string>>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [metricsGroupsOrder, setMetricsGroupsOrder] = useState<Array<string>>([]);
  const [unitsSubcolumnsOrder, setUnitsSubcolumnsOrder] = useState<Array<{ columnName: string; unitsOrder: Array<string>; }>>([]);
  const [expandedBeforeReorder, setExpandedBeforeReorder] = useState<Array<number>>([]);

  const viewNameElement = useRef<HTMLTdsTextFieldElement>(null);
  const viewModalElement = useRef<HTMLTdsModalElement>(null);
  const searchInputRef = useRef<HTMLTdsTextFieldElement>(null);
  const tabsRef = useRef<HTMLTdsFolderTabsElement>(null);

  const handleNameChange = useCallback(
    (e: any) => {
      const currentViewName = e.detail?.target?.value?.trim() || '';
      setViewNameValue(currentViewName);
      const errorStatus = !(currentViewName.length > 0);
      if (errorStatus !== viewNameError) {
        setViewNameError(errorStatus);
      }
    },
    [viewNameError]
  );

  const handleSearchInputUpdate = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedText(currentSearchedText);
    }, []
  );

  const clearViewData = useCallback(() => {
    setViewMetrics([]);
    setViewNameValue('');
    setSelector('');
    setSearchedText('');
    setParamsSelected(false);
    setExpandedParameterGroups([]);
    setExpandedBeforeReorder([]);
    setSelectedTabIndex(0);
    setMetricsGroupsOrder([]);
    setUnitsSubcolumnsOrder([]);
    // collapse all accordions
    const allCategoryAccordions = document.querySelectorAll(`tds-accordion-item[id^=category_item]`);
    allCategoryAccordions.forEach((accordionItem: HTMLTdsAccordionItemElement | any) => {
      accordionItem.expanded = false;
    })

    if (viewNameError) {
      setViewNameError(false);
    }
  }, [viewNameError, setSelector]);

  const updateMetricGroupsOrder = useCallback(() => {
    const tempOrderedGroups: SetStateAction<{ columnName: string; subColumns: Array<string>; }[]> = [];
    
    metricsGroupsOrder.forEach(item => {
      const currentMetric: { columnName: string; subColumns: Array<string>; } = viewMetrics[Number(item)];
      const reorderedUnitsForMetric = unitsSubcolumnsOrder.find(metric => metric.columnName.toLowerCase() === currentMetric.columnName.toLowerCase());

      if (reorderedUnitsForMetric?.unitsOrder && reorderedUnitsForMetric.unitsOrder.length > 1) {
        const reorderedUnits: string[] = [];
        reorderedUnitsForMetric.unitsOrder.forEach((unitItemIndex: string) => {
          reorderedUnits.push(currentMetric.subColumns[Number(unitItemIndex)]);
        });
        tempOrderedGroups.push({ columnName: currentMetric.columnName, subColumns: reorderedUnits });
      } else {
        tempOrderedGroups.push(currentMetric);
      }
    });

    setViewMetrics(tempOrderedGroups);
  }, [metricsGroupsOrder, unitsSubcolumnsOrder, viewMetrics]);

  const updateUnitSubcolumnsOrder = (columnName: string, subcolumnsOrder: string[]) => {
    let tempUnitsSubcolOrder: Array<{ columnName: string; unitsOrder: Array<string>; }> = [...unitsSubcolumnsOrder];
    const groupIndex = tempUnitsSubcolOrder.findIndex(metric => metric.columnName.toLowerCase() === columnName.toLowerCase());
    if (groupIndex > -1) {
      tempUnitsSubcolOrder[groupIndex].unitsOrder = subcolumnsOrder;
    }
    setUnitsSubcolumnsOrder(tempUnitsSubcolOrder);
  }

  const updateSelectedTab = useCallback(
    (e: any) => {
      const newSelectedTabIndex = e?.detail?.selectedTabIndex || 0;
      if (newSelectedTabIndex !== selectedTabIndex) {
        setSelectedTabIndex(newSelectedTabIndex);

        if (newSelectedTabIndex === 1) {
          const tempExpandedCategories: number[] = [];
          groupParams.forEach((category: any, categoryIndex: number) => {
            if (isAccordionItemExpanded(`category_item_${categoryIndex}`)) tempExpandedCategories.push(categoryIndex);
          });
          if (JSON.stringify(tempExpandedCategories) !== JSON.stringify(expandedBeforeReorder)) {
            setExpandedBeforeReorder(tempExpandedCategories);
          }
        } else {
          updateMetricGroupsOrder();
        }
      }
  }, [expandedBeforeReorder, selectedTabIndex, updateMetricGroupsOrder]);

  useEffect(() => {
    const nameTextField = viewNameElement.current;
    if (!nameTextField) return;

    nameTextField.addEventListener('tdsInput', handleNameChange);

    return () => {
      nameTextField.removeEventListener('tdsInput', handleNameChange);
    };
  }, [handleNameChange]);

  useEffect(() => {
    const searchTextField = searchInputRef.current;
    if (!searchTextField) return;

    searchTextField.addEventListener('tdsInput', handleSearchInputUpdate);

    return () => {
      searchTextField.removeEventListener('tdsInput', handleSearchInputUpdate);
    };
  }, [handleSearchInputUpdate]);

  useEffect(() => {
    const viewModal = viewModalElement.current;
    viewModal?.addEventListener('tdsClose', clearViewData);
    return () => viewModal?.removeEventListener('tdsClose', clearViewData);
  }, [clearViewData]);

  useEffect(() => {
    const tabsElement = tabsRef.current;
    tabsElement?.addEventListener('tdsChange', updateSelectedTab);
    return () => tabsElement?.removeEventListener('tdsChange', updateSelectedTab);
  }, [updateSelectedTab]);

  useEffect(() => {
    if (selector?.length > 0 &&
      selector === 'editViewButton' &&
      tableWidgetDetails?.title) {
      setViewNameValue(tableWidgetDetails.title?.text || '');
      setViewMetrics(tableWidgetDetails?.widgets[0]?.properties?.columns || []);
      setExpandedParameterGroups(getTableParamsExpanded(tableWidgetDetails?.widgets[0]?.properties?.columns || [], groupParams))
    }
  }, [selector, tableWidgetDetails]);

  useEffect(() => {
    // reset columns order when view metrics get updated
    const tempAscOrder: Array<string> = [];
    const tempUnitsSubcolOrder: Array<{ columnName: string; unitsOrder: Array<string>; }> = [];
    
    viewMetrics.forEach((element, index) => {
      tempAscOrder.push(index.toString());
      tempUnitsSubcolOrder.push({ columnName: element.columnName, unitsOrder: Array.from({length: element.subColumns.length || 0}, (e, i)=> i.toString())})
    });

    setUnitsSubcolumnsOrder(tempUnitsSubcolOrder);
    setMetricsGroupsOrder(tempAscOrder);
  }, [viewMetrics]);

  const updateViewParams = (
    newCheckedStatus: boolean,
    checkboxValue: string,
    checkboxName: string,
    isParentGroup: boolean
  ) => {
    const updatedList = updateParamsList(
      newCheckedStatus,
      checkboxValue,
      checkboxName,
      isParentGroup,
      viewMetrics,
      groupParams
    );

    setViewMetrics(updatedList);
  };

  const markAllCategoryGroups = (categoryIndex: number, newCheckedStatus: boolean) => {
    const allCategoryCheckboxes = document.querySelectorAll(`tds-checkbox[id^=category_${categoryIndex}_checkbox]`);
    let updatedViewMetrics = [...viewMetrics];

    allCategoryCheckboxes.forEach((parentCheckbox: HTMLTdsCheckboxElement | any) => {
      // there is one special case where not all children checkboxes are checked so parent is checked but indeterminate
      // we need to toggle all unchecked children checkboxes
      if (newCheckedStatus && parentCheckbox.checked && parentCheckbox.indeterminate) {
        const allChildrenCheckboxes = document.querySelectorAll(`tds-checkbox[name^=${parentCheckbox.name}__]`);
        allChildrenCheckboxes.forEach((childCheckbox: HTMLTdsCheckboxElement | any) => {
          if (childCheckbox.checked !== newCheckedStatus) {
            childCheckbox.toggleCheckbox();
            updatedViewMetrics = updateParamsList(
              newCheckedStatus,
              childCheckbox.value,
              childCheckbox.name,
              false,
              updatedViewMetrics,
              groupParams
            );
          }
        })
      }
      
      if (parentCheckbox.checked !== newCheckedStatus) {
        parentCheckbox.toggleCheckbox();
        updatedViewMetrics = updateParamsList(
          newCheckedStatus,
          parentCheckbox.value,
          parentCheckbox.name,
          true,
          updatedViewMetrics,
          groupParams
        );
      }
    });

    setViewMetrics(updatedViewMetrics);
  }

  const updateVisibleCategoryGroups = (categoryIndex: number, visibleGroups: number) => {
    if (visibleCategories[categoryIndex] !== visibleGroups) {
      const nextVisibleCategories = visibleCategories.map((categoryCount, index) => {
        return (index === categoryIndex ? visibleGroups : categoryCount);
      });
      setVisibleCategories(nextVisibleCategories);
    }
  }

  const saveView = async () => {
    const isEditing = selector === 'editViewButton';
    const tempOrderedGroups: SetStateAction<{ columnName: string; subColumns: Array<string>; }[]> = [];
    
    metricsGroupsOrder.forEach(item => {
      const currentMetric: { columnName: string; subColumns: Array<string>; } = viewMetrics[Number(item)];
      const reorderedUnitsForMetric = unitsSubcolumnsOrder.find(metric => metric.columnName.toLowerCase() === currentMetric.columnName.toLowerCase());

      if (reorderedUnitsForMetric?.unitsOrder && reorderedUnitsForMetric.unitsOrder.length > 1) {
        const reorderedUnits: string[] = [];
        reorderedUnitsForMetric.unitsOrder.forEach((unitItemIndex: string) => {
          reorderedUnits.push(currentMetric.subColumns[Number(unitItemIndex)]);
        });
        tempOrderedGroups.push({ columnName: currentMetric.columnName, subColumns: reorderedUnits });
      } else {
        tempOrderedGroups.push(currentMetric);
      }
    });

    const viewMetricsGroups = Object.keys(tempOrderedGroups);
    const currentViewName = viewNameElement.current?.value?.trim() || '';
    setViewNameError(currentViewName.length === 0);

    if (currentViewName.length > 0 && viewMetricsGroups?.length > 0) {
      // we have all necessary data set correctly so we can save
      const tempPayload: DashboardDetails = {
        dashboardReference: isEditing && dashboardReference && dashboardReference?.length > 0 ? dashboardReference : undefined,
        properties: null, // dashboard-level filters to be added when they'll be available 
        title: {
          text: currentViewName,
          isTranslationKeyId: false,
        },
        widgets: [],
      };

      if(isEditing && tableWidgetDetails?.widgets?.length > 0) {
        const widgetIndex = tableWidgetDetails.widgets.findIndex((widget: WidgetSpecification) => widget.type === 'equipmentUsage' && widget.view === 'table');
        
        if(widgetIndex !== -1) {
          const updatedWidget = {
            ...tableWidgetDetails.widgets[widgetIndex],
            properties: {
              ...tableWidgetDetails.widgets[widgetIndex].properties,
              columns: tempOrderedGroups,
            }
          }

          tempPayload.widgets = [...tableWidgetDetails.widgets];
          tempPayload.widgets[widgetIndex] = updatedWidget;
        }
        } else {
          tempPayload.widgets.push({
            widgetReference: undefined,
            type: 'equipmentUsage',
            view: 'table',
            properties: {
              filters: [],
              columns: tempOrderedGroups,
            },
          })
      }

      try {
        const result = isEditing ? await updateDashboard(tempPayload).unwrap() :  await createDashboard(tempPayload).unwrap()
        pushNotification({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: t('ÄndringarnaHarSparats'),
          variant: 'success',
        });

        viewModalElement.current?.closeModal();
        clearViewData();
        updateSelectedView(result.dashboardReference);          
      } catch (err) {
        pushNotification({
          id: uuidv4(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  };

  const deleteView = async () => {
    const isEditing = selector === 'editViewButton';

    try {
      if(dashboardReference && dashboardReference.length > 0 && isEditing) {
        await deleteDashboard(dashboardReference).unwrap()
        pushNotification({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: t('VP_VynHarRaderats'),
          variant: 'success',
        });

          viewModalElement.current?.closeModal();
          clearViewData();
          updateSelectedView('');
          setConfirmationSelector('');
      } 
    } catch (err) {
      pushNotification({
        id: uuidv4(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  const currentViewModal = viewModalElement.current;
  if (selector.length && currentViewModal) {
    currentViewModal.showModal();
  }

  return (
    <>
      <TdsModal
        selector={(selector?.length > 0) ? `#${selector}` : ''}
        size={size}
        prevent={true}
        actions-position='sticky'
        ref={viewModalElement}>
        <span slot='header' style={{ height: '24px' }}>{t(header)}</span>
        <span slot='body'>
          {((dashboardReference.length > 0 &&
            (!tableWidgetDetails?.title ||
              !tableWidgetDetails?.widgets ||
              (tableWidgetDetails?.widgets?.length > 0 && !tableWidgetDetails?.widgets[0]?.properties))) ||
            (selector === 'editViewButton' && dashboardReference.length === 0)) &&
            <TdsMessage
              variant='error'
              header={t('EttFelHarUppstått_FörsökIgenSenare_')}
            />
          }
          <div className={(
            selector === 'createViewButton' ||
            (selector === 'editViewButton' &&
            tableWidgetDetails &&
            tableWidgetDetails.title &&
            tableWidgetDetails.widgets?.length > 0)) ? '' : 'hiddenElement'}>
            <div className={styles.initialRowContainer}>
              <div className={styles.viewNameContainer}>
                <TdsTextField
                  type='text'
                  size='md'
                  ref={viewNameElement}
                  label={t('VP_ViewName')}
                  label-position='outside'
                  placeholder={selector === 'createViewButton' ? t('VP_NewView') : t('VP_ViewName')}
                  state={viewNameError ? 'error' : 'default'}
                  helper={t('_Obligatorisk')}
                  value={viewNameValue}
                />
              </div>
              <div className={styles.searchContainer}>
                <TdsTextField 
                  type='text'
                  modeVariant='primary'
                  ref={searchInputRef}
                  size='md'
                  state='default'
                  placeholder={t('Sök')}
                  helper=''
                  value={searchedText}
                > 
                  {searchedText?.length > 0 ?
                    <TdsIcon className='clickableElement' slot='suffix' name='cross' size='20px' onClick={() => setSearchedText('')} />
                    :                        
                    <TdsIcon slot='suffix' name='search' size='20px' />
                  }
                </TdsTextField>
              </div>
            </div>
            <div className={styles.metricContainerHeader}>
              <TdsFolderTabs
                modeVariant='secondary'
                ref={tabsRef}
                defaultSelectedIndex={0}
                selectedIndex={selectedTabIndex}
              >
                <TdsFolderTab>
                  <button>{t('VP_SelectMetrics')}</button>
                </TdsFolderTab>
                <TdsFolderTab disabled={viewMetrics?.length === 0 || undefined} id='reorder_metrics_tab'>
                  <button>{t('VP_MetricsReorderTitle')}</button>
                </TdsFolderTab>
                { viewMetrics?.length === 0 && 
                  <TdsTooltip
                    placement='top'
                    selector='#reorder_metrics_tab'
                    text={t('DuMåsteVäljaEttEllerFleraAlternativ_')}
                    className='buttonTooltip'
                  />
                }
              </TdsFolderTabs>
            </div>
            { selectedTabIndex === 0 ?
              <div className={styles.metricsContainer}>
                { visibleCategories.every(categoryCount => categoryCount === 0) && searchedText &&
                  <span className={styles.noParamResults}>{t('SökningenGavIngaResultat')}</span> }
                  <TdsAccordion modeVariant='secondary' hideLastBorder>
                    {groupParams.map((category: { categoryName: string | undefined; groups: {}; }, categoryIndex: number) => {
                      const categoryName = stripNonAlphaCharacters(
                        t(getNameTranslationKey(category.categoryName))
                      );
                      const categoryNameContainsSearchedText = categoryName?.toLowerCase()?.includes(searchedText?.toLowerCase());
                      const noSearchedTextFirstCategory = categoryIndex === 0 && !searchedText?.length && selector === 'createViewButton';
                      const categoryContainsSearchedText = searchedText?.length > 0 && (visibleCategories[categoryIndex] > 0 || categoryNameContainsSearchedText);
                      const checkedParamGroups = getGroupsCountPerCategory(
                        Object.keys(category.groups),
                        viewMetrics.map(group => group.columnName)
                      );

                      return <TdsAccordionItem
                        key={`category_${categoryIndex}`}
                        id={`category_item_${categoryIndex}`}
                        paddingReset
                        expanded={categoryContainsSearchedText ||
                                  isAccordionItemExpanded(`category_item_${categoryIndex}`) ||
                                  expandedBeforeReorder.includes(categoryIndex) ||
                                  (!paramsSelected &&
                                    (categoryContainsSearchedText ||
                                    noSearchedTextFirstCategory ||
                                    (selector === 'editViewButton' && checkedParamGroups > 0))
                                  ) || undefined
                                }
                        className={searchedText && visibleCategories[categoryIndex] === 0 && !categoryNameContainsSearchedText ? 'hiddenElement' : undefined}
                        header={`${categoryName} (${checkedParamGroups}/${Object.keys(category.groups).length})`}>
                          <div className={styles.selectButtonsContainer}>
                            <p className={styles.selectLinkContainer}>
                              <TdsLink
                                underline={false}
                                disabled={checkedParamGroups === Object.keys(category.groups).length && allGroupsFullySelected(viewMetrics, category.groups)}
                                onClick={() => markAllCategoryGroups(categoryIndex, true)}>
                                  <span>{t('MarkeraAlla')}</span>
                              </TdsLink>
                            </p>
                            <p>
                              <TdsLink
                                underline={false}
                                disabled={checkedParamGroups === 0}
                                onClick={() => markAllCategoryGroups(categoryIndex, false)}>
                                  <span>{t('AvmarkeraAlla')}</span>
                              </TdsLink>
                            </p>
                          </div>
                          <ParameterGroupsContainer
                            categoryIndex={categoryIndex}
                            categoryNameContainsSearchedText={categoryNameContainsSearchedText}
                            groups={category.groups}
                            propulsionConsumptionUnit={propulsionConsumptionUnit}
                            gasUnit={gasConsumptionUnit}
                            viewMetrics={viewMetrics}
                            expandedParameterGroups={expandedParameterGroups}
                            updateParamsList={updateViewParams}
                            searchedText={searchedText}
                            updateVisibleCategoryGroups={updateVisibleCategoryGroups}
                            setCheckboxMarked={setParamsSelected}
                            updateExpandedParamGroups={setExpandedParameterGroups}
                          />
                      </TdsAccordionItem>
                    })}
                  </TdsAccordion>
              </div>
              :
              <div className={styles.reorderContainer}>
                <div className={styles.metricsContainer}>
                  {viewMetrics?.length > 0 &&
                    <div className={styles.draggableTableContainer}>
                      <TableHeaderReorderContainer
                        selectedMetrics={viewMetrics}
                        propulsionConsumptionUnit={propulsionConsumptionUnit}
                        gasUnit={gasConsumptionUnit}
                        updateMetricGroupsOrder={setMetricsGroupsOrder}
                        updateUnitSubcolumnsOrder={updateUnitSubcolumnsOrder}
                      />
                    </div>
                  }
                  <div className={`${styles.reorderMetricsHeader} ${!viewMetrics?.length && styles.smallerPaddingTop}`}>
                    <TdsIcon name='info' size='20' className={styles.infoIcon} />
                    <span>
                      {viewMetrics?.length > 0 ?
                        t('VP_MetricsReorderBody') :
                        t('VP_SelectMetrics')}
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </span>
        <span slot='actions' className={styles.actionButtons}>
            {
              selector === 'editViewButton' && (
                <TdsButton
                  id='deleteViewModal'
                  size='sm'
                  text={t("TaBort")}
                  variant='secondary'
                  type='button'
                  onClick={() => setConfirmationSelector('deleteViewModal')}
                  />
              )
            }
            <div className={styles.rightSideButtons}>
              <TdsButton
                data-dismiss-modal
                size='sm'
                text={t('Avbryt')}
                type='button'
                variant='secondary'
              />
              {isSavingDashboard || isUpdatingDashboard ? (
                <div className={styles.buttonSpinner}>
                  <TdsSpinner size='sm' />
                </div>
              ) : (
                <TdsButton
                  size='sm'
                  text={selector === 'editViewButton' ? t('Spara') : t('VP_CreateView')}
                  type='button'
                  disabled={
                    !(Object.keys(viewMetrics)?.length > 0) ||
                    viewNameError ||
                    (!viewNameError && viewNameValue.length === 0) ||
                    undefined
                  }
                  onClick={saveView}
                />
              )}
            </div>
        </span>
      </TdsModal>
      <ConfirmationModal
        size='sm'
        selector={confirmationSelector}
        header={t('VP_RaderaVy')}
        message={t('VP_ÄrDuSäker')}
        onCancel={() => setConfirmationSelector('')}
        onConfirm={deleteView}
        confirmButtonText={t('TaBort')}
        danger
        />
    </>
  );
};

export default ViewModal;