import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import UnitItem from './UnitItem';

type ReorderableUnitItemProps = {
  id: any;
  subColumnDetails: string;
  columnName: string
  propulsionConsumptionUnit: string;
  gasUnit: string;
}

const ReorderableUnitItem = ({ id, subColumnDetails, columnName, propulsionConsumptionUnit, gasUnit }: ReorderableUnitItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <UnitItem
      ref={setNodeRef}
      id={`reorderableUnitItem_${id}`}
      style={style}
      subColumnDetails={subColumnDetails}
      columnName={columnName}
      propulsionConsumptionUnit={propulsionConsumptionUnit}
      gasUnit={gasUnit}
      {...attributes}
      {...listeners}
    />
  );
}

export default ReorderableUnitItem;