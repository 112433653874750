import { getAvailableIdentifier, getMatchingPropForVehicleName } from "./report/tableUtils";

declare global {
  interface Window {
    MOPINION_USER_LANGUAGE?: string;
  }
}

export const getIdentifierForEqRef = (externalEquipmentRef: string | null, equipmentsData: any, staffDataIdentifier: string) => {
  if (staffDataIdentifier && equipmentsData && externalEquipmentRef) {
    const selectedEquipment = equipmentsData.equipments?.filter((equipment: TableWidgetEquipment ) => equipment?.externalEquipmentReference === externalEquipmentRef);
    
    if (selectedEquipment && selectedEquipment.length) {
      const selectedEquipmentIdentifier = getAvailableIdentifier(
        getMatchingPropForVehicleName(staffDataIdentifier), selectedEquipment[0]?.equipmentInfo
      );

      return selectedEquipmentIdentifier;
    }
  }

  return '';
}

export const localDate = (eventTime: Date) => (
  new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(eventTime))
);

export const getTableParamsExpanded = (groups: Array<{
  columnName: string;
  subColumns: Array<string>
}>, groupParams: any) => {
  const expanded: string[] = [];
  
  groupParams.forEach((category: { groups: {}; }, categoryIndex: any) => {
    const groupKeys: any = Object.keys(category.groups);

    groupKeys.forEach((groupIdentifier: string, groupIndex: any) => {
      if (typeof groups.find(group => group.columnName === groupIdentifier) !== 'undefined') {
        expanded.push(`category_${categoryIndex}_${groupIdentifier}_${groupIndex}_container`)
      }
    })
  })

  return expanded;
}

interface MopinionSrv {
  ALL_KEYS: string[];
  setLanguage: (formKey: string, language: string) => void;
}

declare const srv: MopinionSrv;

export const setMopinionLanguage = (language: string): void => {
  // The following global variable can be used by Mopinion admins to limit the deployment of a form to certain languages:
  if (window) window.MOPINION_USER_LANGUAGE = language;

  // srv is an object set by Mopinion.
  // https://mopinion.atlassian.net/wiki/spaces/KB/pages/665288753/How+do+I+programmatically+switch+to+another+translation
  if (language && (typeof srv !== 'undefined')) {
    const languageWithIntentionalExtraQuotes = `"${language}"`;
    language = language === 'en-GB' ? 'default' : languageWithIntentionalExtraQuotes;

    const formKeys = srv?.ALL_KEYS || [];
    formKeys.forEach(formKey => {
      srv.setLanguage(formKey, language);
    })
  }
}

export const capitalizeFirstLetter = (stringToCapitalize: string = '') => {
  if (!stringToCapitalize.length) return stringToCapitalize;

  return stringToCapitalize.substring(0, 1).toUpperCase() + stringToCapitalize.substring(1);
}