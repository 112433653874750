import { useState } from 'react';
import {
  closestCenter,
  DndContext, 
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import ReorderableItem from '../common/reorderTable/ReorderableItem';
import styles from '../../styles/ViewModal.module.css';

type TableHeaderReorderContainerProps = {
  selectedMetrics: Array<{
    columnName: string;
    subColumns: Array<string>
  }>;
  propulsionConsumptionUnit: string;
  gasUnit: string;
  updateMetricGroupsOrder: Function;
  updateUnitSubcolumnsOrder: Function;
};

const TableHeaderReorderContainer = ({
  selectedMetrics,
  propulsionConsumptionUnit,
  gasUnit,
  updateMetricGroupsOrder,
  updateUnitSubcolumnsOrder
} : TableHeaderReorderContainerProps) => {
  const arr = Array.from({length: selectedMetrics.length || 0}, (e, i)=> i.toString());
  // eslint-disable-next-line
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(arr);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const handleDragStart = (event: { active: any }) => {
    const {active} = event;
    
    setActiveId(active.id);
  }
  
  const handleDragEnd = (event: { active: any; over: any; }) => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const rearrangedColumnsOrder = arrayMove(items, oldIndex, newIndex);
        updateMetricGroupsOrder(rearrangedColumnsOrder);
        
        return rearrangedColumnsOrder;
      });
    }
    
    setActiveId(null);
  }
  
  return (
    <div className='fullWidth'>
      <div className={styles.dndContainer}>
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            {items.map(itemIndex => {
              const currentColumn = selectedMetrics[Number(itemIndex)];
              return <ReorderableItem
                  key={itemIndex}
                  id={itemIndex}
                  columnDetails={currentColumn}
                  propulsionConsumptionUnit={propulsionConsumptionUnit}
                  gasUnit={gasUnit}
                  updateUnitSubcolumnsOrder={updateUnitSubcolumnsOrder}
                />
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  )
}

export default TableHeaderReorderContainer;