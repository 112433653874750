import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';
import { TdsDropdownCustomEvent } from '@scania/tegel';

import { useUpdateDefaultDashboardMutation } from '../../state/views/query';
import '../../styles/Overview.css';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';

type Props = {
  views: View[];
  updateSelectedView: Function;
  placeholder?: string;
  labelPosition?: 'inside' | 'outside';
  label?: string;
  size?: 'md' | 'xs' | 'sm' | 'lg';
  modeVariant?: 'primary' | 'secondary';
};

const ViewsDropdown = ({
  views = [],
  updateSelectedView,
  placeholder,
  labelPosition,
  label,
  size,
  modeVariant,
}: Props) => {
  const [defaultSelectedView, setDefaultSelectedView] = useState(
    views.find((view) => view.isDefault === true) || views[0]
  );
  const viewsDropdownRef = useRef<HTMLTdsDropdownElement>(null);
  const [updateDefaultDashboard] = useUpdateDefaultDashboardMutation();
  const { t } = useTranslation();
  const location = useLocation();

  const isDashboardRoute = location.pathname.includes('dashboard');

  // Function to preprocess views: group, sort, and add dummy rows
  const preprocessViews = () => {
    const groupedViews = {
      translationKey: views.filter((view) => view.title.isTranslationKeyId),
      nonTranslationKey: views.filter((view) => !view.title.isTranslationKeyId),
    };

    const sortedTranslationKeyViews = groupedViews.translationKey.sort((a, b) =>
      a.title.text.localeCompare(b.title.text)
    );
    const sortedNonTranslationKeyViews = groupedViews.nonTranslationKey.sort(
      (a, b) => a.title.text.localeCompare(b.title.text)
    );

    const myDashboardsTitle = isDashboardRoute ? t('VP_MyDashboards') : t('VP_ViewsCustom');
    const defaultDashboardsTitle = isDashboardRoute ? t('VP_DefaultDashboards') : t('VP_ViewsDefault');

    if(groupedViews.nonTranslationKey.length < 1) {
      return [...sortedTranslationKeyViews]
    }

    return [
      { dashboardReference: 'dummy-non-translation', title: { text: myDashboardsTitle, isTranslationKeyId: false } },
      ...sortedNonTranslationKeyViews,
      { dashboardReference: 'dummy-translation', title: { text: defaultDashboardsTitle, isTranslationKeyId: true } },
      ...sortedTranslationKeyViews,
    ];
  };

  const handleSelectOption = useCallback(
    (e: TdsDropdownCustomEvent<{ name: string; value: string }>) => {
      const currentDefaultOption = views.find((view) => view.isDefault);
      const selectedOption = views.find(
        (view) => view.dashboardReference === e.detail.value
      );

      if (currentDefaultOption && selectedOption && currentDefaultOption.dashboardReference !== selectedOption.dashboardReference) {
        updateDefaultDashboard(selectedOption.dashboardReference);
      }

      updateSelectedView(e.detail.value);
    },
    [updateDefaultDashboard, updateSelectedView, views]
  );

  useEffect(() => {
    const defaultView = views.find((view) => view.isDefault === true);
    if (defaultView) {
      setDefaultSelectedView(defaultView);
    }
  }, [views]);

  useEffect(() => {
    const viewsDropdown = viewsDropdownRef.current;
    if (!viewsDropdown) return;

    viewsDropdown.addEventListener('tdsChange', handleSelectOption);

    return () => {
      viewsDropdown.removeEventListener('tdsChange', handleSelectOption);
    };
  }, [handleSelectOption]);

  const processedViews = preprocessViews()

  return (
    <div className='viewsDropdownContainer'>
      <TdsDropdown
        ref={viewsDropdownRef}
        placeholder={placeholder || t('Välj')}
        defaultValue={defaultSelectedView.dashboardReference}
        label={label || t('ÄndraVy')}
        label-position={labelPosition || 'outside'}
        modeVariant={modeVariant || 'primary'}
        size={size || 'md'}>
        {processedViews.map((view) => (
          <TdsDropdownOption
            key={view.dashboardReference}
            value={view.dashboardReference}
            className={view.dashboardReference.startsWith('dummy-') ? 'isDropdownTitle' : ''}
            >
            <span className={view.dashboardReference.startsWith('dummy-') ? 'isDropdownTitleText' : ''}>
                {view.dashboardReference.startsWith('dummy-')
                ? (view.title.isTranslationKeyId
                ? stripNonAlphaCharacters(t(getNameTranslationKey(view.title.text))).toUpperCase()
                : view.title.text.toUpperCase())
                : (view.title.isTranslationKeyId
                ? stripNonAlphaCharacters(t(getNameTranslationKey(view.title.text)))
                : view.title.text)}
            </span>
          </TdsDropdownOption>
        ))}
      </TdsDropdown>
    </div>
  );
};

export default ViewsDropdown;
