import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles/DataTableHeader.module.css';

import { stripNonAlphaCharacters } from '../../../utils/report/tableUtils';
import { headerTitleToTranslationKey } from '../../../utils/report/convertTranslationKeys';
import UnitSubcolumnsContainer from '../../containers/UnitSubcolumnsReorderContainer';

type ItemProps = {
  id: string;
  columnDetails: {
    columnName: string;
    subColumns: string[];
  };
  propulsionConsumptionUnit: string;
  gasUnit: string;
  updateUnitSubcolumnsOrder: Function;
  style: {
    transform?: string;
    transition?: string;
  }
};

const Item = forwardRef<HTMLDivElement, ItemProps>(({id, columnDetails, propulsionConsumptionUnit, gasUnit, updateUnitSubcolumnsOrder, ...props }, ref) => {
  const { t } = useTranslation();
  
  return (
    <div {...props}
      ref={ref}
      id={`reorderTableHeader${id}`}
      key={`reorderKeyTableHeader${id}`}>
      <div
        id={`reorderableColumnHeader${id}`}
        className={`${styles.firstRowHeaderCell} leftAlignedText ${styles.reorderableCol} clickableElement ${styles.noLeftPadding}`}>
        <div className={`${styles.thContent} fullWidth`}>
          <div
            className={`fullWidth ${styles.overflowTh}`}
            id={`overflowingTableHeader${id}`}>
            {stripNonAlphaCharacters(
              t(headerTitleToTranslationKey(columnDetails?.columnName))
            )}
          </div>
        </div>
      </div>
      { columnDetails && columnDetails.subColumns &&
        <UnitSubcolumnsContainer
          columnName={columnDetails.columnName}
          subColumns={columnDetails.subColumns}
          propulsionConsumptionUnit={propulsionConsumptionUnit}
          gasUnit={gasUnit}
          updateUnitSubcolumnsOrder={updateUnitSubcolumnsOrder}
        />
      }
    </div>
  )
});

export default Item;