import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsBodyCell,
  TdsHeaderCell,
  TdsMessage,
  TdsSpinner,
  TdsTable,
  TdsTableBody,
  TdsTableBodyRow,
  TdsTableHeader,
  TdsTableToolbar,
} from '@scania/tegel-react';
import { TdsTableToolbarCustomEvent } from '@scania/tegel';

import FuelReportExportButton from './FuelReportExportButton';
import { useGetFuelReportQuery } from '../../state/fuelReport/query';
import { adjustOdometerToProfileSetting, getAvailableIdentifier, sortFuelTableData } from '../../utils/report/tableUtils';
import '../../styles/GeneralStyles.css';
import { localDate } from '../../utils/general';
import { refuelingEventToTranslationKey } from '../../utils/report/convertTranslationKeys';

interface FuelReportTableProps {
  selectedGroup?: string;
  vehicleIdentifier: 'registrationNumber' | 'chassisNumber' | 'alias';
  propulsionConsumptionUnit: string;
  startDate: string;
  endDate: string;
  staffDetails: StaffDetails;
}

const filterGroup = (item: FuelReportItem, group: string = 'groupFilterAll') => {
  return group === 'groupFilterAll' || item.equipment.externalEquipmentGroupReference.includes(group);
}

const FuelReportTable = ({ selectedGroup, vehicleIdentifier, propulsionConsumptionUnit, startDate, endDate, staffDetails }: FuelReportTableProps) => {
  const { t } = useTranslation();

  const filterTableRef = useRef<HTMLTdsTableElement>(null);
  const {
    data: serverData,
    isLoading: isFuelEventsLoading,
    isSuccess: fuelEventsLoadedSuccessfully,
    isError: isErrorLoadingFuelEvents,
    isFetching: isFuelEventsFetching,
  } = useGetFuelReportQuery({startTime: startDate, endTime: endDate, language: staffDetails.language});
  const [tableFilter, setTableFilter] = useState<string>('');
  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filteredData, setFilteredData] = useState<FuelReportItem[]>([]);

  useEffect(() => {
    const freeTextFilter = (item: FuelReportItem) => {
      const { alias, registrationNumber, chassisNumber } = item.equipment;
    
      const searchableFields = [
      alias,
      registrationNumber,
      chassisNumber,
      localDate(item.eventTime),
      adjustOdometerToProfileSetting(item.odometerInMeters, propulsionConsumptionUnit).toLocaleString(),
      `${item.oldLevel} % -> ${item.newLevel} %`,
      `${item.increaseInPercentage} %`,
      t(refuelingEventToTranslationKey(item.refuelType)),
      item.driver?.name,
      ].filter(Boolean);
    
      const lowerCaseFilter = tableFilter.trim().toLocaleLowerCase();
    
      const matches = searchableFields.some((field) => {
      const fieldValue = field?.toString().toLocaleLowerCase();
      return fieldValue?.includes(lowerCaseFilter);
      });
    
      return matches;
    };
  
    if (serverData) {
      const newFilteredData = serverData
        .filter((r: FuelReportItem) => filterGroup(r, selectedGroup))
        .filter(freeTextFilter);
  
      setFilteredData(
        sortFuelTableData(sortField, sortDirection, vehicleIdentifier, newFilteredData)
      );
    }
  }, [serverData, selectedGroup, tableFilter, vehicleIdentifier, propulsionConsumptionUnit, sortDirection, sortField, t]);
  
  const sortByColumn = (event: CustomEvent<{ tableId: string; columnKey: string; sortingDirection: 'asc' | 'desc'; }>) => {
    if (event?.detail?.columnKey && filteredData) {
      setSortField(event.detail.columnKey);
      setSortDirection(event.detail.sortingDirection);
      setFilteredData(sortFuelTableData(event.detail.columnKey, event.detail.sortingDirection, vehicleIdentifier, filteredData));
    }
  };

  return (
    <>
      {(isFuelEventsFetching || isFuelEventsLoading) && <div className='spinnerContainer'><TdsSpinner size='md' /></div>}
      { isErrorLoadingFuelEvents && (
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      )}
      {!isFuelEventsFetching && !isFuelEventsLoading && fuelEventsLoadedSuccessfully && filteredData &&
        <TdsTable tableId='filter-table' noMinWidth responsive ref={filterTableRef}>
          <TdsTableToolbar onTdsFilter={(event: TdsTableToolbarCustomEvent<{ query: string }>) => setTableFilter(event.detail.query)} tableTitle={t('Filter')} filter>
            <div slot='end' className='exportExcelButton'>
              <FuelReportExportButton
                buttonText={t('ExporteraExcel')}
                buttonType='primary'
                disabled={!filteredData?.length}
                sheetTitle={t('Rapport')}
                tableData={filteredData}
                staffDetails={staffDetails}
                interval={{
                  startDate,
                  endDate,
                }}
              />
            </div>
          </TdsTableToolbar>
          <TdsTableHeader>
            <TdsHeaderCell cellKey='equipment' cellValue={t('Utrustning')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='time' cellValue={t('Tid_Tankning_')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='odometer' cellValue={t('Vägmätarställning')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='event' cellValue={t('Händelse')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='change' cellValue={t('Förändringar')} />
            <TdsHeaderCell cellKey='increase' cellValue={t('Ökning')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='driver' cellValue={t('Förare')} sortable onTdsSort={(event) => sortByColumn(event)} />
            <TdsHeaderCell cellKey='address' cellValue={t('Adress')} sortable onTdsSort={(event) => sortByColumn(event)} />
          </TdsTableHeader>
          <TdsTableBody>
            {!filteredData.length && 
            <TdsTableBodyRow>
              <td className='tds-u-p2' colSpan={8}>{t('IngenDataAttVisa')}</td>
            </TdsTableBodyRow>}
            {filteredData.map((row, index) => {
              const { alias, chassisNumber, registrationNumber } = row.equipment;
              const equipment = getAvailableIdentifier(vehicleIdentifier, { alias, chassisNumber, registrationNumber });

              return (
                <TdsTableBodyRow key={index}>
                  <TdsBodyCell cellKey='equipment'>{equipment}</TdsBodyCell>
                  <TdsBodyCell cellKey='time'>{row.eventTime && localDate(row.eventTime)}</TdsBodyCell>
                  <TdsBodyCell cellKey='odometer'>{adjustOdometerToProfileSetting(row.odometerInMeters, propulsionConsumptionUnit).toLocaleString() } { propulsionConsumptionUnit.includes('miles') ? t('Miles'): t('Km') }</TdsBodyCell>
                  <TdsBodyCell cellKey='event'>{t(refuelingEventToTranslationKey(row.refuelType))}</TdsBodyCell>
                  <TdsBodyCell cellKey='change'>{row.oldLevel} % -&gt; {row.newLevel} %</TdsBodyCell>
                  <TdsBodyCell cellKey='increase'>{row.increaseInPercentage} %</TdsBodyCell>
                  <TdsBodyCell cellKey='driver'>{row.driver?.name || t('IngetFörar_Id')}</TdsBodyCell>
                  <TdsBodyCell cellKey='address'>{row.address}</TdsBodyCell>
                </TdsTableBodyRow>
              )
            })}
          </TdsTableBody>
        </TdsTable>
      }
    </>
    );
};

export default FuelReportTable;