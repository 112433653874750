import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Item from './Item';

type ReorderableItemProps = {
  id: any;
  columnDetails: { columnName: string; subColumns: string[] };
  propulsionConsumptionUnit: string;
  gasUnit: string;
  updateUnitSubcolumnsOrder: Function;
}

const ReorderableItem = ({ id, columnDetails, propulsionConsumptionUnit, gasUnit, updateUnitSubcolumnsOrder }: ReorderableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <Item
      ref={setNodeRef}
      id={`column_${id}`}
      style={style}
      updateUnitSubcolumnsOrder={updateUnitSubcolumnsOrder}
      columnDetails={columnDetails}
      propulsionConsumptionUnit={propulsionConsumptionUnit}
      gasUnit={gasUnit}
      {...attributes}
      {...listeners}
    />
  );
}

export default ReorderableItem;