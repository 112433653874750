import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../../styles/DataTableHeader.module.css';
import { getUnitTypeForSubcolumn, stripBraces } from '../../../utils/report/tableUtils';
import { unitToTranslationKey } from '../../../utils/report/convertTranslationKeys';
import { getUnitBasedOnProfileSettings } from '../../../utils/unitsCategories';

type UnitItemProps = {
  id: string;
  subColumnDetails: string;
  columnName: string;
  propulsionConsumptionUnit: string;
  gasUnit: string;
  style: {
    transform?: string;
    transition?: string;
  }
};

const UnitItem =  forwardRef<HTMLDivElement, UnitItemProps>(({id, subColumnDetails, columnName, propulsionConsumptionUnit, gasUnit, ...props }, ref) => {
  const { t } = useTranslation();
  
  const unitType = getUnitTypeForSubcolumn(columnName, subColumnDetails);
    
  return (
    <div {...props}
      ref={ref}
      id={`reorderUnitHeader${id}`}
      key={`reorderKeyUnitHeader${id}`}
      className={`rightAlignedText ${styles.subTh} ${styles.reorderableUnit}`}>
        <div className={styles.thContent}>
          <span>
            {stripBraces(
              t(unitToTranslationKey(
                getUnitBasedOnProfileSettings({ name: subColumnDetails,
                                                unitType: unitType },
                                                propulsionConsumptionUnit,
                                                gasUnit)
            )))?.toLowerCase()}
          </span>
        </div>
    </div>
  )
});

export default UnitItem;