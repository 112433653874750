import { useState } from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import ReorderableUnitItem from '../common/reorderTable/ReorderableUnitItem';

type UnitSubcolumnsContainerProps = {
  columnName: string;
  subColumns: Array<string>;
  propulsionConsumptionUnit: string;
  gasUnit: string;
  updateUnitSubcolumnsOrder: Function;
}

const UnitSubcolumnsContainer = ({ columnName, subColumns, propulsionConsumptionUnit, gasUnit, updateUnitSubcolumnsOrder }: UnitSubcolumnsContainerProps) => {
  const arr = Array.from({length: subColumns?.length || 0}, (e, i)=> i.toString());
  // eslint-disable-next-line
  const [activeUnitId, setActiveUnitId] = useState(null);
  const [unitItems, setUnitItems] = useState(arr);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const handleDragStartForUnit = (event: { active: any }) => {
    const {active} = event;
    
    setActiveUnitId(active.id);
  }
  
  const handleDragEndForUnit = (event: { active: any; over: any; }) => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      setUnitItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const rearrangedUnitSubcolumnsOrder = arrayMove(items, oldIndex, newIndex);

        updateUnitSubcolumnsOrder(columnName, rearrangedUnitSubcolumnsOrder);
        
        return rearrangedUnitSubcolumnsOrder;
      });
    }
    
    setActiveUnitId(null);
  }

  return <DndContext 
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragStart={handleDragStartForUnit}
    onDragEnd={handleDragEndForUnit}
  >
    <SortableContext
      items={unitItems}
      strategy={horizontalListSortingStrategy}
    >
      {unitItems.map(itemIndex =>
        <ReorderableUnitItem
          key={itemIndex}
          id={itemIndex}
          subColumnDetails={subColumns[Number(itemIndex)]}
          columnName={columnName}
          propulsionConsumptionUnit={propulsionConsumptionUnit}
          gasUnit={gasUnit}
        />
      )}
    </SortableContext>
  </DndContext>
}

export default UnitSubcolumnsContainer;