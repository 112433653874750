import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { TdsButton, TdsDivider, TdsIcon, TdsMessage, TdsTextField, TdsTooltip } from '@scania/tegel-react';
import { useTranslation } from 'react-i18next';

import { skipToken } from '@reduxjs/toolkit/query';
import { useGetEquipmentsQuery } from '../../state/equipments/query';

import styles from '../../styles/DataTable.module.css';
import style from '../../styles/TableWidget.module.css';
import '../../styles/GeneralStyles.css';

import chevronUpIcon from '../../assets/chevronUp.svg';
import chevronDownIcon from '../../assets/chevronDown.svg';

import {
  createDeepCopy,
  displayFormattedValue,
  filterTableRowsOnSearch,
  getAvailableIdentifier,
  getDashboardGroupParameters,
  getMatchingPropForVehicleName,
  sortRows,
  transformToAcceptedGroupPayload
} from '../../utils/report/tableUtils';

import DataTableHeader from '../overview/DataTableHeader';
import DriverDetailsTable from '../overview/DriverDetailsTable';
import ExportExcelButton from '../export/ExportExcelButton';
import Skeleton from 'react-loading-skeleton';

type TableWidgetProps = {
  widgetIndex: number;
  staffDetails: StaffDetails;
  tableWidgetSpecs: any;
  periodStart: string;
  periodEnd: string;
  selectedGroup: string;
  externalEquipmentRef?: string | null;
  fuelTypes: string[];
  vehicleTypes: string[];
  isSelectedDashboardNotEditable: boolean;
};

const TableWidget = ({
  widgetIndex,
  staffDetails,
  tableWidgetSpecs,
  periodStart,
  periodEnd,
  selectedGroup,
  externalEquipmentRef,
  fuelTypes,
  vehicleTypes,
  isSelectedDashboardNotEditable
}: TableWidgetProps) => {
  const { t } = useTranslation();
  const [tableDataContent, setTableDataContent] = useState<TableDataStructure>({ equipments: [], average: [], total: [] });
  const [expandedVehicleRows, setExpandedVehicleRows] = useState(externalEquipmentRef ? [externalEquipmentRef] : []);
  const [sortDetails, setSortDetails] = useState({ sortField: '', sortOrder: ''});
  const [searchedText, setSearchedText] = useState('');
  const searchElementRef = useRef<HTMLTdsTextFieldElement>(null);
  // const [sortedTableData, setSortedTableData] = useState<TableDataStructure>({ equipments: [], average: [] });  

  const tableWidgetMetrics = getDashboardGroupParameters(tableWidgetSpecs?.properties?.columns);

  const {
    data: equipmentsData,
    isLoading: isEquipmentsLoading,
    isSuccess: equipmentsLoadedSuccessfully,
    isError: isErrorLoadingEquipments,
    isFetching: isEquipmentsFetching,
  } = useGetEquipmentsQuery(
    staffDetails &&
    periodStart &&
    periodEnd &&
    tableWidgetMetrics
      ? {
          queryStart: periodStart,
          queryStop: periodEnd,
          propulsionConsumptionUnit: staffDetails
            ? staffDetails.propulsionConsumptionUnit
            : null,
          gasConsumptionUnit: staffDetails ? staffDetails.gasConsumptionUnit : null,
          fuelTypes: fuelTypes,
          // assuming we'll receive the UTC difference as '-02:45' or '+05:00', the + sign is not mandatory
          utcDiff: staffDetails && staffDetails.customer?.utcDiff ? staffDetails.customer.utcDiff : null,
          parameters: tableWidgetMetrics,
          equipmentTypes: vehicleTypes,
          externalEquipmentGroupReference: transformToAcceptedGroupPayload(selectedGroup),
          externalEquipmentReference: externalEquipmentRef
        }
      : skipToken
  );  

  const handleSearchForText = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedText(currentSearchedText);
    }, []
  );

  useEffect(() => {
    if (staffDetails && equipmentsLoadedSuccessfully && !isEquipmentsFetching && !isEquipmentsLoading) {
      const searchTextField = searchElementRef.current;
      if (!searchTextField) return;

      searchTextField.addEventListener('tdsInput', handleSearchForText);

      return () => {
        searchTextField.removeEventListener('tdsInput', handleSearchForText);
      };
    }
  }, [handleSearchForText, staffDetails, equipmentsLoadedSuccessfully, isEquipmentsFetching, isEquipmentsLoading]);
  
  useEffect(() => {
    if (equipmentsLoadedSuccessfully && !isEquipmentsFetching && !isEquipmentsLoading) {
      setTableDataContent(equipmentsData);
    }
  }, [equipmentsData, equipmentsLoadedSuccessfully, isEquipmentsFetching, isEquipmentsLoading]);


  const updateExpandedVehicleRows = (clickedVehicleReference: string) => {
    let tempExpandedRows = [...expandedVehicleRows];
    if (!tempExpandedRows.includes(clickedVehicleReference)) {
      tempExpandedRows.push(clickedVehicleReference);
    }
    else {
      // the row was expanded so we need to remove it from the expanded rows list upon click
      tempExpandedRows = tempExpandedRows.filter((vehicleRowRef) => vehicleRowRef !== clickedVehicleReference);
    }

    setExpandedVehicleRows(tempExpandedRows);
  }
  
  const sortTableRows = (sortField: string, sortOrder: 'asc' | 'desc' = 'asc', tableDataToSort = tableDataContent) => {
    if (sortField) {
      setSortDetails({ sortField: sortField, sortOrder: sortOrder });
      
      const sortedEquipments = sortRows(
        sortField,
        sortOrder,
        tableDataToSort.equipments
      );

      const tableDataDeepCopy = createDeepCopy(tableDataToSort);
      tableDataDeepCopy.equipments = sortedEquipments;

      setTableDataContent(tableDataDeepCopy);
    }
  };

  useEffect(() => {
    if (equipmentsData) {
      const filteredEquipments = filterTableRowsOnSearch(searchedText, equipmentsData, staffDetails.vehicleIdentifier, sortDetails);
      setTableDataContent(filteredEquipments);
    }
  }, [searchedText, staffDetails.vehicleIdentifier, sortDetails, equipmentsData]);

  return (
    <div className={style.tableWidgetContainer}>
      {isErrorLoadingEquipments &&
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      }
      {(isEquipmentsLoading || isEquipmentsFetching) &&
        <table className={style.skeletonContent}>
          <tr className={styles.tableRow}>
            <td colSpan={4}><Skeleton /></td>
          </tr>
          {Array(3).fill(1).map((rowIndex) =>
            <tr className={styles.tableRow} key={`tableWidget_${widgetIndex}_skeletonRow_${rowIndex}`}>
              {Array(4).fill(1).map(() => <td><Skeleton /></td>)}
            </tr>
          )}
        </table>
      }
      {tableWidgetSpecs &&
        equipmentsLoadedSuccessfully &&
        !(isEquipmentsLoading || isEquipmentsFetching) && (
          <>
            <div className={style.tableWidgetHeader}>
              {t('Fordonsdata') /* OR the table widget name when BE has support for it and the widget has a name */} 
              <div className='exportVehicleDataButton'>
                <ExportExcelButton
                  buttonText={t('ExporteraFordonsdata')}
                  buttonType='secondary'
                  sheetTitle={t('Rapport')}
                  typeOfData='overview'
                  equipmentRef={externalEquipmentRef}
                  tableData={tableDataContent}
                  tableWidgetColumns={tableWidgetSpecs?.properties?.columns || []}
                  staffDetails={staffDetails}
                  interval={{
                    startDate: periodStart,
                    endDate: periodEnd,
                  }}
                  disabled={
                    isEquipmentsFetching ||
                    (tableDataContent &&
                      tableDataContent.equipments?.length === 0)
                  }
                />
              </div>
            </div>
            <div className={style.searchFieldContainer}>
              <div className={style.searchField}>
                <TdsTextField
                  type='text'
                  size='sm'
                  ref={searchElementRef}
                  placeholder={t('Sök')}
                  value={searchedText}
                  modeVariant='primary'
                  disabled={isEquipmentsFetching ||
                    isErrorLoadingEquipments ||
                    (tableDataContent &&
                      tableDataContent.equipments?.length === 0 &&
                      !searchedText?.length) ||
                      (externalEquipmentRef !== null && externalEquipmentRef !== undefined)
                  }
                  className={externalEquipmentRef !== null && externalEquipmentRef?.length ? 'readOnlyInput' : ''}
                >
                  { (searchedText?.length > 0 && equipmentsData?.equipments?.length) || externalEquipmentRef?.length ?
                    <TdsIcon className='clickableElement' slot='suffix' name='cross' size='20px' onClick={() => { setSearchedText(''); setTableDataContent(equipmentsData); /*clearPreselectedEquipment*/}} />
                    :                        
                    <TdsIcon slot='suffix' name='search' size='20px' />
                  }
                </TdsTextField>
              </div>              
              <div className={style.dividerContainer}>
                <TdsDivider orientation='vertical' />
              </div>
              <>
                <span id='editViewButtonContainer'>
                  <TdsButton
                    type='button'
                    variant='secondary'
                    size='sm'
                    disabled={isSelectedDashboardNotEditable || undefined}
                    id='editViewButton'
                    onClick={() => {/*setModalSelector('editViewButton')*/}}>
                    <TdsIcon slot='icon' size='16px' name='edit'></TdsIcon>
                  </TdsButton>
                </span>
                { isSelectedDashboardNotEditable &&
                  <TdsTooltip
                    placement='bottom'
                    selector='#editViewButtonContainer'
                    text={t('VP_DefaultDashboardsCanNotEdit')}
                    className='buttonTooltip'
                  />
                }
                { !isSelectedDashboardNotEditable &&
                  <TdsTooltip
                    placement='bottom'
                    selector='#editViewButtonContainer'
                    text={t('VP_EditWidget')}
                    className='buttonTooltip'
                  />
                }
              </>
            </div>
            <div className={style.tableWidget}>
              <table className='tds-table--responsive fullWidth'>
                <DataTableHeader
                  vehicleIdentifier={getMatchingPropForVehicleName(
                    staffDetails.vehicleIdentifier
                  )}
                  propulsionConsumptionUnit={staffDetails.propulsionConsumptionUnit}
                  tableWidgetSpecs={tableWidgetSpecs}
                  parameterDetails={tableDataContent?.equipments[0]?.parameters || tableWidgetSpecs?.properties?.columns}
                  sortTableContent={sortTableRows}
                />            
                { tableDataContent.equipments?.length === 0 ?
                  <tbody>
                    <tr>
                      <td className='stickyLeft'>
                        <div className='messagePadding'>
                          <TdsMessage variant='information' minimal header={t('IngenDataAttVisa')}/>
                        </div>
                      </td>
                      <td colSpan={(tableWidgetSpecs?.properties?.columns?.length) || 1}></td>
                    </tr>
                  </tbody>
                  :              
                  <tbody>
                    { /* average and total rows will be displayed ONLY when NOT coming from other apps */ }
                    {!externalEquipmentRef &&
                    <>
                      <tr className={`${styles.tableRow}`}>
                        <td
                          key='keyTotal1_1'
                          className={`${style.leftAlignedText} ${styles.totalRowCell} ${styles.paddedContent} ${styles.stickyLeft}`}>
                          {t('Totalt')}
                        </td>
                        {tableWidgetMetrics.map((subcolumn: string, index: number) => {
                          const paramToDisplay = tableDataContent.total?.find(metric => metric.name.toLowerCase() === subcolumn.toLowerCase());
                          
                          return <td
                              key={`keyTotal${index + 2}`}
                              className={`rightAlignedText ${styles.totalRowCell}`}>
                              {paramToDisplay ? displayFormattedValue(paramToDisplay) : ''}
                            </td>
                        })}
                      </tr>
                      <tr className={`${styles.tableRow}`}>
                        <td
                          key='keyAverage1_1'
                          className={`${style.leftAlignedText} ${styles.averageRowCell} ${styles.paddedContent} ${styles.stickyLeft}`}>
                          {t('TF_FleetAverage')}
                        </td>
                        {tableWidgetMetrics.map((subcolumn: string, index: number) => {
                          const paramToDisplay = tableDataContent.average?.find(metric => metric.name.toLowerCase() === subcolumn.toLowerCase());
                          
                          return <td
                              key={`keyAverage${index + 2}`}
                              className={`rightAlignedText ${styles.averageRowCell}`}>
                              {paramToDisplay ? displayFormattedValue(paramToDisplay) : ''}
                            </td>
                        })}
                      </tr>
                    </>
                    }
                    {tableDataContent.equipments?.map(
                      (singleVehicle: TableWidgetEquipment, rowIndex: number) => {
                        const isExpandedRow = expandedVehicleRows.includes(singleVehicle.externalEquipmentReference);
                        const currentVehicleIdentifier = getAvailableIdentifier(
                          getMatchingPropForVehicleName(
                            staffDetails.vehicleIdentifier
                          ),
                          singleVehicle.equipmentInfo
                        );

                        return (
                          <Fragment key={`vehicleRow${rowIndex + 1}Container`}>
                            <tr
                              key={`vehicleRow${rowIndex + 1}`}
                              onClick={(e) => { e.preventDefault(); updateExpandedVehicleRows(singleVehicle.externalEquipmentReference)}}
                              // the clickable class should be added only when token permissions allow user to see driver details
                              className={`clickableElement ${styles.tableRow} ${
                                isExpandedRow ? styles.expandedRow :
                                (rowIndex % 2 === 1 || externalEquipmentRef?.length) ? styles.oddRow : styles.evenRow
                              } `}>
                              <td
                                key={`keyVehicleRow${rowIndex + 1}Group1Col1`}
                                className={`${style.leftAlignedText} stickyLeft ${
                                  isExpandedRow ? styles.expandedRow : (rowIndex % 2 === 1 || externalEquipmentRef?.length) ? styles.oddRow : styles.evenRow
                                }`}>
                                <div style={{display: 'flex', gap: '16px'}} key={`keyVehicleRowImage${rowIndex + 1}Group1Col1`}>
                                  <img
                                    id={rowIndex === 0 ? 'shepherd-driver-details-button' : ''}
                                    src={isExpandedRow ? chevronUpIcon : chevronDownIcon}
                                    alt={isExpandedRow ? t('Stäng') : t('Öppen')}
                                    width={14}
                                  />
                                  {currentVehicleIdentifier}
                                </div>
                              </td>
                              {tableWidgetMetrics.map((subcolumn: string, index: number) => {
                                const paramToDisplay = singleVehicle.parameters.find(metric => metric.name.toLowerCase() === subcolumn.toLowerCase());
                                const formattedValue = paramToDisplay ? displayFormattedValue(paramToDisplay) : '';

                                return <td
                                  key={`keyVehicleRow${rowIndex + 1}Group${index + 2}`}
                                  className={`rightAlignedText ${styles.cellWidth}`}>
                                  <div style={{ minWidth: 'max-content' }}>
                                    {!isNaN(formattedValue) ? formattedValue.toLocaleString() : formattedValue}
                                  </div>
                                </td>
                              })}
                            </tr>
                            { // the table below returns driver rows only when permissions are met on the BE side to view driver data
                              isExpandedRow &&
                                <DriverDetailsTable
                                  staffDetails={staffDetails}
                                  periodStart={periodStart}
                                  periodEnd={periodEnd}
                                  sortDetails={sortDetails}
                                  equipmentReference={singleVehicle.externalEquipmentReference}
                                  tableWidgetSpecs={tableWidgetSpecs}
                                  parameterDetails={tableWidgetMetrics}
                                  vehicleRowIndex={externalEquipmentRef?.length ? 1 : rowIndex}
                                  vehicleData={singleVehicle}
                                />
                            }
                          </Fragment>
                        )
                      }
                    )}
                  </tbody>
                }
              </table>
            </div>
          </>
        )}
    </div>
  );
};

export default TableWidget;