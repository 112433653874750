import { TdsButton, TdsIcon } from '@scania/tegel-react';

import { createExcelWorkbook } from '../../utils/report/excelExport';
import { createDriverDataExcelWorkbook } from '../../utils/report/driverDataExport';

type Props = {
  buttonText: string;
  buttonType: 'primary' | 'secondary' | 'ghost' | 'danger';
  sheetTitle: string;
  typeOfData: 'driver' | 'overview';
  vehicleRow?: TableWidgetEquipment | null;
  equipmentRef?: string | null;
  tableData: any;
  tableWidgetColumns: Array<{ columnName: string; subColumns: Array<string> }> | undefined;
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
  disabled: boolean
};

const ExportExcelButton = ({
  buttonText,
  buttonType,
  sheetTitle,
  typeOfData = 'overview',
  vehicleRow = null,
  equipmentRef = null,
  tableData,
  tableWidgetColumns = [],
  staffDetails,
  interval,
  disabled
}: Props) => {
  const tryExport = () => {
    if (typeOfData === 'overview') {
      createExcelWorkbook({
        sheetTitle,
        equipmentRef,
        tableData,
        tableWidgetColumns,
        staffDetails,
        interval,
      })
    } else {
      createDriverDataExcelWorkbook({
        sheetTitle,
        tableData,
        tableWidgetColumns,
        vehicleRow,
        staffDetails,
        interval,
      })
    }
  }

  return (
    <TdsButton
      type='button'
      variant={buttonType}
      mode-variant='primary'
      size='sm'
      text={buttonText}
      disabled={disabled || undefined}
      onClick={tryExport}
    >
      <TdsIcon slot='icon' size='16px' name='export'></TdsIcon>
    </TdsButton>
  );
};

export default ExportExcelButton;
