import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';
import { useGetDashboardSpecificationQuery, useGetUserViewsQuery } from '../state/views/query';

import GroupsDropdown from '../components/dropdowns/GroupsDropdown';
import ViewsDropdown from '../components/dropdowns/ViewsDropdown';

import '../styles/GeneralStyles.css';
import styles from '../styles/Dashboard.module.css';

import { TdsButton, TdsDivider, TdsIcon, TdsLink, TdsMessage, TdsPopoverMenu, TdsPopoverMenuItem, TdsSpinner } from '@scania/tegel-react';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';
import DashboardWidgets from '../components/dashboard/DashboardWidgets';

import FiltersButton from '../components/filters/FiltersButton';
import FiltersContent from '../components/filters/FiltersContent';
import ToastContainer from '../components/ToastContainer';
import DashboardModal from '../components/dashboard/DashboardModal';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const Dashboard = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);

  const { showDashboardPage } = useFlags();

  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedDashboardRef, setSelectedDashboardRef] = useState('');
  const [dashboardStartDate, setDashboardStartDate] = useState(defaultStartDate);
  const [dashboardEndDate, setDashboardEndDate] = useState(defaultEndDate);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [staffRetriesLimiter, setStaffRetriesLimiter] = useState<number>(1);
  const [toastsToDisplay, setToastsToDisplay] = useState<Array<Toast>>([]);
  const [dashboardModalSelector, setDashboardModalSelector] = useState('');
  const [isSelectedDashboardNotEditable, setIsSelectedDashboardNotEditable] = useState(false);

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);

  
  useEffect(() => {
    if (isStaffLoadingError && staffRetriesLimiter <= 5) {
      setStaffRetriesLimiter(prevState => prevState + 1)
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData, staffRetriesLimiter]);

  const searchNewInterval = ({
    startDate,
    endDate,
  }: {
    [key: string]: string;
  }) => {
    setDashboardStartDate(dayjs(startDate).format(dateTimeFormat));
    setDashboardEndDate(dayjs(endDate).format(dateTimeFormat));
  };

  const {
    data: fetchedDashboards,
    isSuccess: isDashboardsLoadedSuccessfully,
    isError: isErrorLoadingDashboards,
    isLoading: isDashboardsLoading,
    isFetching: isDashboardsFetching,
  } = useGetUserViewsQuery(isStaffLoadingSuccess ? null : skipToken);
  
  const {
    data: selectedDashboardSpecs,
    isSuccess: dashboardSpecsLoadedSuccessfully,
    isError: isErrorLoadingDashboardSpecs,
    isFetching: isDashboardSpecsFetching,
    isLoading: isDashboardSpecsLoading,
  } = useGetDashboardSpecificationQuery(
    selectedDashboardRef && selectedDashboardRef.length > 0 ? selectedDashboardRef : skipToken
  );

  useEffect(() => {
    if (isDashboardsLoadedSuccessfully && !isDashboardsFetching && fetchedDashboards?.length) {
      const selectedDashboard = fetchedDashboards.find((dashboard: Dashboard) => dashboard.isDefault === true);

      if (selectedDashboard && selectedDashboard.dashboardReference) {
        setSelectedDashboardRef(selectedDashboard.dashboardReference);
        setIsSelectedDashboardNotEditable(selectedDashboard?.title && (selectedDashboard.title.isTranslationKeyId || selectedDashboard.dashboardReference === '03b89b46-6b28-4828-935b-cc12ec97e4bf'));
      }
    }
  }, [fetchedDashboards, isDashboardsFetching, isDashboardsLoadedSuccessfully]);

  const pushNotificationToToast = (newNotification: Toast) => {
    setToastsToDisplay((toasts) => [...toasts, newNotification]);

    setTimeout(() => {
      removeToast(newNotification.id);
    }, 5000);
  };

  const removeToast = (toastId: number | string) => {
    setToastsToDisplay((toasts) =>
      toasts.filter((toast) => toast.id !== toastId)
    );
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const selectedCount = (selectedFuelTypes.length > 0 ? 1 : 0) + (selectedVehicleTypes.length > 0 ? 1 : 0);

  return (
    <div className='mainContainer isolatedContainer'>
      <div className='stickyHeader'>
        <div className='pageHeader'>
          <div className='header2'>{t('Anslagstavla')}</div>
          {loggedIn && staffData && showDashboardPage && (
            <div className={styles.inlineFlexContainer}>
              {(isDashboardsFetching || isDashboardsLoading) && (
                <div className={styles.spinnerContainer}>
                  <TdsSpinner size='md' />
                </div>
              )}
              {isDashboardsLoadedSuccessfully && !isDashboardsFetching && fetchedDashboards.length > 0 && (
                <>
                  <ViewsDropdown
                    views={fetchedDashboards}
                    updateSelectedView={setSelectedDashboardRef}
                    size='sm'
                    labelPosition='inside'
                    label={t('Anslagstavla')}
                    modeVariant='secondary'
                  />
                  <TdsButton
                    type='button'
                    variant='primary'
                    size='sm'
                    text={t('VP_CreateDashboard')}
                    onClick={() => setDashboardModalSelector('createDashboard')}
                  />
                  { dashboardSpecsLoadedSuccessfully && !isDashboardSpecsFetching && !isDashboardSpecsLoading &&
                    <>
                      <TdsButton
                        type='button'
                        id='dashboard_actions_button'
                        variant='secondary'
                        size='sm'
                        mode-variant='primary'
                        className={styles.dahsboardKebabButton}
                      >
                        <TdsIcon slot='icon' size='16px' name='kebab'></TdsIcon>
                      </TdsButton>
                      <TdsPopoverMenu id='dashboard-actions-menu' placement='left-start' selector='#dashboard_actions_button'>
                        <TdsPopoverMenuItem disabled={isSelectedDashboardNotEditable ? true : undefined}>
                          <button onClick={() => setDashboardModalSelector('editDashboard')}>
                            <TdsIcon name='edit' />
                            {t('Redigera')}
                          </button>
                        </TdsPopoverMenuItem>
                        <TdsDivider />
                        <TdsPopoverMenuItem>
                          <button>
                            <TdsIcon name='copy' />
                            {t('Clone')}
                          </button>
                        </TdsPopoverMenuItem>
                        <TdsDivider />
                        <TdsPopoverMenuItem disabled={isSelectedDashboardNotEditable ? true : undefined}>
                          <button>
                            <TdsIcon name='trash' />
                            {t('TaBort')}
                          </button>
                        </TdsPopoverMenuItem>
                      </TdsPopoverMenu>
                    </>
                  }

                  <DashboardModal
                    header={dashboardModalSelector === 'editDashboard' ? t('VP_EditDashboard'): t('VP_CreateADashboard') }
                    selector={dashboardModalSelector}
                    dashboardDetails={selectedDashboardSpecs || null}
                    selectedDashboardRef={selectedDashboardRef}
                    pushNotification={pushNotificationToToast}
                    updateSelectedDashboard={setSelectedDashboardRef}
                    setSelector={setDashboardModalSelector}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {loggedIn && staffData && !isStaffLoading && !showDashboardPage && (
          <div className='pageContentContainer'>{t('DuHarInteBehörighet_')}</div>
        )}
        {loggedIn && showDashboardPage && (
          <div className='pageContentContainer'>
            {(isErrorLoadingGroups || isErrorLoadingDashboards) && (
              <TdsMessage
                variant='error'
                header={t('EttFelHarUppstått_FörsökIgenSenare_')}
              />
            )}

            {(isStaffLoading || isCustomerGroupsFetching) && (
              <div className='spinnerContainer'>
                <TdsSpinner size='md' />
              </div>
            )}

            {staffData && groupsLoadedSuccessfully && (
              <div className={styles.flexContainer}>
                <div className={styles.groupsDropdownContainer}>
                  <GroupsDropdown
                    groups={customerGroups}
                    groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                    onSelection={setSelectedGroup}
                    isDisabled={false}
                    variantMode='primary'
                    label={t('Grupper')}
                    labelPosition='inside'
                  />
                </div>
                <div className={styles.overviewDatePickerContainer}>
                  <DateTimeRangePicker
                    periodStart={dashboardStartDate}
                    periodEnd={dashboardEndDate}
                    staffStartOfWeek={staffData.customer?.startOfWeek}
                    staffLanguage={staffData.language}
                    searchNewInterval={searchNewInterval}
                    modeVariant='primary'
                  />
                </div>
                <div className={styles.filtersButtonContainer}>
                  <FiltersButton
                    isExpanded={isExpanded}
                    onToggle={handleToggle}
                  />
                  {selectedCount > 0 && (
                    <>
                      <span className={styles.filterBadge}>
                        <span>{selectedCount}</span>
                      </span>
                      <TdsLink>
                        <span onClick={() => { setSelectedFuelTypes([]); setSelectedVehicleTypes([]); }} className={styles.clearFiltersButton}>
                          {t('VP_ClearFilters')}
                        </span>
                      </TdsLink>
                    </>
                  )}

                </div>
                <div className='fullWidth'>
                  <FiltersContent
                    isExpanded={isExpanded} 
                    onClose={handleClose}
                    selectedFuelTypes={selectedFuelTypes} 
                    setSelectedFuelTypes={setSelectedFuelTypes} 
                    selectedVehicleTypes={selectedVehicleTypes} 
                    setSelectedVehicleTypes={setSelectedVehicleTypes} 
                    externalEquipmentReference={null} 
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      { showDashboardPage && isErrorLoadingDashboardSpecs &&
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      }
      { showDashboardPage && (isDashboardSpecsFetching || isDashboardSpecsLoading) && (
        <div className='spinnerContainer'>
          <TdsSpinner size='md' />
        </div>
      )}
      {loggedIn &&
      showDashboardPage &&
      staffData &&
      groupsLoadedSuccessfully &&
      dashboardSpecsLoadedSuccessfully &&
      !isDashboardSpecsFetching &&
      !isDashboardSpecsLoading && (
        <DashboardWidgets
          staffDetails={staffData}
          periodStart={dashboardStartDate}
          periodEnd={dashboardEndDate}
          selectedGroup={selectedGroup}
          selectedDashboardSpecs={selectedDashboardSpecs}
          fuelTypes={selectedFuelTypes}
          vehicleTypes={selectedVehicleTypes}
        />
      )}      
      {toastsToDisplay.length > 0 && (
        <ToastContainer toasts={toastsToDisplay} removeToast={removeToast} />
      )}
    </div>
  );
};

export default Dashboard;
